<template>
  <div class="c_nodata" >
    <img :src="require('@/assets/nodata.svg')" class="c_nodata-pic"/>
    <div class="c_nodata-text">{{text || $t('normal.nodata')}}</div>
  </div>
</template>

<script>
export default {
  props: {
    text: String,
  },
}
</script>

<style lang="scss" scoped>
.c_nodata-pic{
  width: 240px;
  display: block;
  margin: 0 auto;
  opacity: 0.1;
}
.c_nodata-text{
  text-align: center;
  font-weight: bold;
  font-size: 18px;
  color: rgba(255, 255, 255, 0.146);
}
.darkmode{
.c_nodata-pic{
}
}
</style>
