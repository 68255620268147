<template>
  <div class="trend" >
    <Header/>

     <!-- <div class="header g_panel-header">
      <span class="back icon-back" role="button" aria-label="back" tabindex="0" @click.stop="backHandler" />
      <h1 class="title">
        <img :src="require('@/assets/about/logo_s@2x.png')" alt="icon" class="taglogo">
        Little Star Trending
      </h1>
    </div> -->
    <div class="trend-con">
      <div class="trend-title" >
        <div class="title">
          <!-- <i class="trend-title-icon icon-github_icon"></i>
          Github Trending
          <i class="icon-loading g_loading trend-title-loading" v-if="loading && list.length"></i> -->
        </div>

        <div class="selectw">
          <el-select class="select" @change="getData" v-model="day" :placeholder="$t('trend.selectDatePlaceHolder')">
            <el-option
              v-for="item in dayList"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
          <el-select filterable   @change="getData" class="select" v-model="languageSelect" :placeholder="$t('trend.selectLangPlaceHolder')">
            <el-option
              v-for="(item, key) in languageList"
              :key="key"
              :label="key === 'All' ? $t('trend.allLang') : key"
              :value="key">
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="listw g_scroll" >
        <div class="list list_loading" v-if="loading && !list.length">
          <div class="item">
            <div class="item-title">
              <div class="item-title-text"></div>
            </div>
            <div class="item-con"></div>
            <div class="item-language">
              <div class="item-language-item">
              </div>
            </div>
            <div class="item-bt">
              <div class="item-bt-datas">
              </div>
              <div class="item-bt-date">
              </div>
            </div>
          </div>
          <div class="item">
            <div class="item-title">
              <div class="item-title-text"></div>
            </div>
            <div class="item-con"></div>
            <div class="item-language">
              <div class="item-language-item">
              </div>
            </div>
            <div class="item-bt">
              <div class="item-bt-datas">
              </div>
              <div class="item-bt-date">
              </div>
            </div>
          </div>
          <div class="item">
            <div class="item-title">
              <div class="item-title-text"></div>
            </div>
            <div class="item-con"></div>
            <div class="item-language">
              <div class="item-language-item">
              </div>
            </div>
            <div class="item-bt">
              <div class="item-bt-datas">
              </div>
              <div class="item-bt-date">
              </div>
            </div>
          </div>
          <div class="item">
            <div class="item-title">
              <div class="item-title-text"></div>
            </div>
            <div class="item-con"></div>
            <div class="item-language">
              <div class="item-language-item">
              </div>
            </div>
            <div class="item-bt">
              <div class="item-bt-datas">
              </div>
              <div class="item-bt-date">
              </div>
            </div>
          </div>
          <div class="item">
            <div class="item-title">
              <div class="item-title-text"></div>
            </div>
            <div class="item-con"></div>
            <div class="item-language">
              <div class="item-language-item">
              </div>
            </div>
            <div class="item-bt">
              <div class="item-bt-datas">
              </div>
              <div class="item-bt-date">
              </div>
            </div>
          </div>
          <div class="item">
            <div class="item-title">
              <div class="item-title-text"></div>
            </div>
            <div class="item-con"></div>
            <div class="item-language">
              <div class="item-language-item">
              </div>
            </div>
            <div class="item-bt">
              <div class="item-bt-datas">
              </div>
              <div class="item-bt-date">
              </div>
            </div>
          </div>
        </div>
        <div class="list g_clearfix_after" ref="list">
          <a class="item"  target="_blank" :href="item.repourl" v-for="item in list" :key="item.name" >
            <div class="item-title">
              <div class="item-title-text" target="_blank" >{{item.author}}/<strong class="name">{{item.reponame}}</strong><i class="icon-external-link"></i> </div>
              <div class="item-loading icon-loading loading-icon g_loading" v-if="item.isLoading"></div>
            </div>
            <div class="item-con">{{item.repodesc}}</div>
            <div class="item-language">
              <span class="item-language-item" v-if="item.language" :style="`border-color: ${language[item.language] && language[item.language].color}; color: ${language[item.language] && language[item.language].color};`">
                <!-- <span class="item-language-item-dot" :style="`background-color: ${language[item.language].color}`"></span> -->
                {{item.language}}
              </span>
            </div>
            <div class="item-bt">
              <div class="item-bt-datas">
                <div class="data">
                  <i class="data-icon icon-star"></i>{{item.stars|parseNum}}
                </div>
                <div class="data">
                  <i class="data-icon icon-fork"></i>{{item.forks|parseNum}}
                </div>
                <div class="item-bt-users">
                  <!-- <div class="avatar" v-for="(user, index) in item.builtby" :style="`background-image: url(${user.avatarp})`" :key="index"></div> -->
                </div>
              </div>
              <div class="item-bt-date">
                {{item.laststars|parseNum}} stars {{lastsStarsMap[day]}}
              </div>
            </div>
          </a>
        </div>
        <Nodata v-if="!loading && !list.length"></Nodata>
      </div>
    </div>
    <Footer/>
  </div>
</template>
<script>
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import { updateTime, parseNum } from '@/util/func'
import language from '@/util/language.json'
// import languagePopular from '@/util/langPopular.json'
// import { githubProxyHost } from '@/config/'
import Nodata from '@/components/Nodata'

export default {
  components: {
    Nodata,
    Header,
    Footer,
  },
  data () {
    return {
      showDetailModal: false,
      language,
      languageList: language,
      // [{
      //   label: 'Popular',
      //   options: languagePopular,
      // }, {
      //   label: 'All',
      //   options: language,
      // }],
      dayList: [{
        value: 'daily',
        label: this.$t('trend.daily'),
      }, {
        value: 'weekly',
        label: this.$t('trend.weekly'),
      }, {
        value: 'monthly',
        label: this.$t('trend.monthly'),
      }],
      lastsStarsMap: {
        daily: 'today',
        weekly: 'this week',
        monthly: 'this month',
      },
      day: 'daily',
      languageSelect: 'All',
      list: [],
      page: 1,
      totalPage: 0,
      loading: false,
      starMap: {},
    }
  },
  watch: {
    stars (val) {
      if (val) {
        this.initStarMap()
      }
    },
    showDetailModal (val) {
      if (!val) {
        this.initStarMap()
      }
    },
  },
  filters: {
    updateTime,
    parseNum,
  },
  mounted () {
    const { duration, language } = this.$route.query
    if (duration) this.day = decodeURIComponent(duration)
    if (language) this.languageSelect = decodeURIComponent(language)
    this.getData()
    this.initList()
    this.initStarMap()
  },
  methods: {
    showDetail (item) {
      const id = this.starMap[item.author + '/' + item.reponame]
      const star = this.stars[id]
      this.showDetailModal = true
      this.$refs.detail.$emit('trendshow', star || item)
    },
    onScroll (e) {
      const scrollTop = e.target.scrollTop
      if (scrollTop > 12) {
        this.isScroll = true
      } else {
        this.isScroll = false
      }
    },
    initList () {
      try {
        if (this.languageSelect === 'All' && this.day === 'daily') {
          const data = window.localStorage.getItem('littlestar-trending')
          if (data) {
            const list = JSON.parse(window.localStorage.getItem('littlestar-trending'))
            this.list = list
          }
        }
      } catch (e) {
        // 不处理
      }
    },
    initStarMap () {
      if (this.stars) {
        this.starMap = {}
        for (const i in this.stars) {
          const star = this.stars[i]
          if (star.full_name === 'developedbyed/glass-website') {
            console.log(star)
          }
          if (star && !star.unStar) {
            this.starMap[star.full_name] = star.id
          }
        }
        this.$forceUpdate()
      }
    },
    handleUnStar (item) {
      const id = this.starMap[item.author + '/' + item.reponame]
      const star = this.stars[id]
      this.$toast({
        msg: this.$t('starsPanel.unstarConfirm', { name: item.author + '/' + item.reponame }),
        isConfirm: true,
      }).then(() => {
        item.isLoading = true
        this.$forceUpdate()
        this.unStar([star]).then(() => {
          delete this.starMap[item.author + '/' + item.reponame]
          this.$toast({
            msg: this.$i18n.t('starsPanel.unStarTip'),
          })
          this.$forceUpdate()
          item.isLoading = false
        }).finally(() => {
          item.isLoading = false
        })
      })
    },
    handleStar (repo) {
      repo.isLoading = true
      this.$forceUpdate()
      this.getRepoInfo(repo.author + '/' + repo.reponame).then((res) => {
        this.newStar({
          stars: [res],
        }).then(() => {
          repo.isLoading = false
          this.$forceUpdate()
          this.showStarEditPanel(res)
        })
      })
    },
    getData () {
      // this.github.search.repos({
      //   q: `created:>${DateFormat(new Date(new Date().getTime() - this.day * 24 * 60 * 60 * 1000))}`,
      //   sort: 'stars',
      //   order: 'desc',
      //   per_page: 100,
      //   page: this.page,
      // }).then((res) => {
      //   console.log(res)
      //   this.list = res.data.items
      //   this.totalPage = Math.ceil(res.data.total_count / 20)
      // })
      this.loading = true
      this.list = []
      this.$refs.list.scrollTop = 0
      this.$http.get('/github/trend/', {
        section: '', language: this.languageSelect === 'All' ? '' : this.languageSelect, since: this.day, spoken_language_code: '',
      }).then((res) => {
        this.$router.replace({
          query: {
            language: encodeURIComponent(this.languageSelect),
            duration: encodeURIComponent(this.day),
          },
        })
        this.list = res.list
        if (this.languageSelect === 'All' && this.day === 'daily') {
          window.localStorage.setItem('littlestar-trending', JSON.stringify(this.list))
        }
        // this.list.forEach((item) => {
        //   item.builtby.forEach((user) => {
        // user.avatarp = user.avatar.replace(/^(https:\/\/.*)(\/u\/.*)$/, `${githubProxyHost}/avatar$2`)
        //   })
        // })
      }).finally(() => {
        this.loading = false
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.g_panel-header{
  border-bottom: 1px solid rgba(214, 214, 214, 0.5);
}
.el-select-dropdown__item.selected{
  color: #424344;
}
</style>
<style lang="scss" scoped>
.trend-con{
  max-width: 1275px;
  margin: 0 auto;
}
.listw{
  padding: 12px 24px 0;
  // height: calc(100vh);
  box-sizing: border-box;
  overflow: hidden;
  // overflow-y: auto;
}
.trend-title{
  // position: absolute;
  width: 100%;
  // right: 0;
  // top: 0;
  box-sizing: border-box;
  // background: #F9F9F9;
  font-family: 'FredokaOne';
  font-size: 36px;
  padding: 32px 24px;
  display: flex;
  z-index: 2;
  justify-content: space-between;
  align-items: center;
  transition:padding 0.4s ease, box-shadow 0.4s ease, font-size 0.4s ease;
  .selectw{
    height: 32px;
    .select{
      margin-left: 16px;
      vertical-align: top;
    }
    /deep/ .el-input__inner{
      border-radius: 999px;
      width: 125px;
      line-height: 32px;
      height: 32px;
      border: 1px solid rgba(217, 217, 217, 0.63);
      box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1);
    }
    /deep/ .select:nth-child(2) .el-input__inner{
        width: 140px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    /deep/ .el-input__icon{
      line-height: 32px;
    }
    /deep/ .el-select .el-input__inner:focus,
    /deep/  .el-input.is-focus .el-input__inner{
      border-color: #cdcdcd;
    }
  }
}
.trend-title-loading{
  font-size: 24px;
  color: #cdcdcd;
}
.trend-title_scroll{
  border-bottom: 1px solid rgba(214, 214, 214, 0.5);
  padding: 12px 24px;
  // box-shadow: 0 4px 4px rgba(0,0,0,0.1);
  background-image: linear-gradient(-180deg, rgba(251, 251, 251, 0.09), #f1f1f1);
  font-size: 32px;
}
.trend-title-icon{
  /* github_icon */
  background: linear-gradient(180deg, #9C4FFF 0%, #2E0068 100%);
  background-clip: text;
  color: transparent;
}
.list{
  clear: both;
  padding-bottom: 64px;
  &::after{
    content: '';
    clear: both;
  }
}
.item{
  float: left;
  // height: 160px;
  cursor: pointer;
  box-sizing: border-box;
  width: calc((100% - 24px * 2) / 3);
  background: linear-gradient(180deg, #FFFFFF 0%, #f9f9f9 128.97%);
  // background: #fff;
  box-shadow: 0px 3px 13px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  padding: 24px;
  transition: 0.2s ease;
  &:hover{
    // background: linear-gradient(180deg, #FFFFFF 0%, #f3f3f3 128.97%);
    box-shadow: 0px 3px 16px rgba(28, 48, 78, 0.2), 0px 1px 2px rgba(0, 0, 0, 0.06);
  }
  margin: 0 24px 24px 0;
}
.item:nth-child(3n){
  margin-right: 0;
}
@media (max-width: 1500px) {
  .item{
    width: calc((100% - 24px) / 2);
  }

  .item:nth-child(3n){
    margin-right: 24px;
  }
  .item:nth-child(2n){
    margin-right: 0;
  }
}
@media (max-width: 1200px) {
  .item{
    width: 100%;
  }
  .item{
    margin-right: 0;
  }
}
.item-title{
  display: flex;
}
.item-loading{
  height: 24px;
  font-size: 24px;
  color: #bdbdbd;
  margin-left: 16px;
}
.item-title-text{
  display: block;
  text-decoration: none;
  flex: 1;
  height: 26px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-style: normal;
  font-size: 18px;
  line-height: 26px;
  margin-bottom: 8px;
  color: #2F2F2F;
  position: relative;
  word-break: break-all;
  &:hover{
    // text-decoration: underline;
    [class^="icon-"] {
      visibility: visible;
    }
  }
  .name{
    font-weight: bold;
  }
  [class^="icon-"] {
    font-size: 14px;
    margin-left: 8px;
    color: #9a9a9a;
    vertical-align: 1px;
    visibility: hidden;
    // position: absolute;
    // right: -14px;
  }
}
.item-title-star{
  font-size: 20px;
  margin-left: 16px;
  .icon-1, .icon-2{
    cursor: pointer;
    height: 20px;
    width: 20px;
    position: relative;
    [class^="icon-"]{
       background: linear-gradient(324.52deg, #F49200 6.33%, #FFD671 63.71%);
      background-clip: text;
      color: transparent;
      position: absolute;
      left: 0;
      top: 0;
      transition: 0.2s ease;
    }
    [class^="icon-"]:nth-child(2){
      opacity: 0;
      visibility: hidden;
    }
    &:hover{
      [class^="icon-"]:nth-child(1){
        opacity: 0;
        visibility: hidden;
      }
      [class^="icon-"]:nth-child(2){
        opacity: 1;
        visibility: visible;
      }
    }
  }
}
.item-con{
  font-size: 14px;
  line-height: 20px;
  color: #767676;
  height: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-bottom: 8px;
}
.item-language{
  height: 16px;
}
.item-language-item{
  // background: #EEFFFC;
  border: 1px solid #3DB39E;
  box-sizing: border-box;
  border-radius: 16px;
  font-size: 12px;
  line-height: 14px;
  font-weight: bold;
  color: #3DB39E;
  padding: 0 8px;
  display: inline-block;
}
.item-language-item-dot{
  height: 8px;
  width: 8px;
  border-radius: 8px;
  margin-right: 4px;
  display: inline-block;
}
.item-bt{
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
  .users{

  }
}
.item-bt-users{
  display: flex;
  .avatar{
    height: 16px;
    width: 16px;
    border-radius: 999px;
    background-size: cover;
    margin-right: 8px;
  }
}
.item-bt-datas{
  display: flex;
  font-size: 14px;
  color: #575757;
  .data{
    margin-right: 16px;
  }
  .data-icon:nth-child(1) {
    font-size: 16px;
  }
  .data-icon{
    font-size: 14px;
    color: #575757;
    margin-right: 4px;
  }
}
.item-bt-date{
  /* Update 0 day ago */
  font-size: 14px;
  color: rgba(155, 155, 155, 0.56);
}

// list-loading
.list_loading{
  .item{
    .item-title{
      height: 26px;
      margin-bottom: 8px;
      width: 80%;
    }
    .item-language{
      background: none;
    }
    .item-language-item{
      width: 200px;
      height: 16px;
      border: none;
      background: rgb(242, 242, 242);
    }
    .item-bt{
      background: none;
      margin-top: 8px;
      .item-bt-datas{
        height: 16px;
        width: 100px;
      }
    }
    .item-con{
      height: 32px;
      width: 80%;
    }
    div{
      background: rgb(242, 242, 242);
      border-radius: 4px;
    }
  }
}

.detailpop{
  transform: translate3d(0,0,0);
  height: calc(100vh);
  width: 100%;
  position: absolute;
  z-index: 50;
  right: 0;
  top: 0;/* Rectangle 51 */

  background: rgba(202, 202, 202, 0.2);
  backdrop-filter: blur(4px);
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
  // backdrop-filter: blur(4px);
  /* Note: backdrop-filter has minimal browser support */
  transition: 0.2s ease;
  .detail{
    transition: 0.2s ease;
    transform: translate3d(100%,0,0);
    position: absolute;
    z-index: 50;
    right: 0;
    top: 0;/* Rectangle 51 */
    width: 70%;
    background: rgba(255, 255, 255, 0.8);
    box-shadow: 0px 0px 84px 20px rgba(0, 0, 0, 0.4);
  }
}
.detailpop_show{
  visibility: visible;
  opacity: 1;
  .detail{
    transform: translate3d(0,0,0);
  }
}

//darkMode
.trend.darkmode{
  background: #1B1B1B;
  .listw{
    background: #1B1B1B;
  }
  .trend-title{
    color: #fff;
    background: #1B1B1B;
  }
  .trend-title_scroll{
    border-color:  #2b2b2b;;
  }
  .trend-title-icon{
    /* github_icon */
    background: linear-gradient(180deg, #9C4FFF 0%, #7D17FF 100%);
    background-clip: text;
    color: transparent;
  }
  .select{
    /deep/ .el-input__inner{
      border-color:#383838;
      background: linear-gradient(180deg, #464543 4.84%, #3C3B39 98.82%);
      box-shadow: 0px 0.5px 0px rgba(32, 32, 32, 0.5);
      color: #CACACA;
    }
    /deep/  .el-input__inner:focus,/deep/ .el-input.is-focus .el-input__inner{
      border-color: #1f1f1f;
    }
  }
  .trend-title_scroll{
    background-image: linear-gradient(180deg, #464543 5%, #3C3B39 99%);
    box-shadow: 0 0.5px 0 0 rgba(32, 32, 32, 0.5);
  }

  .item{
    // background: linear-gradient(180deg, #464543 4.84%, #3C3B39 98.82%);
    background: linear-gradient(180deg, #383736 4.84%, #2f2f2d 98.82%);
    box-shadow: 0px 3px 4px rgba(32, 32, 32, 0.25);
    &:hover{
      background: linear-gradient(180deg, #464543 4.84%, #3C3B39 98.82%);
      box-shadow: 0px 3px 34px rgba(0, 0, 0, 0.4);
    }
  }
  .item-title-text{
    color: #fff;
  }
  .item-loading{
   color: #fff;
  }
  .item-con{
    color: #B5B5B5;
  }
  .item-bt-datas{
    color: #CECECE;
    .data-icon{
      color: #cfcfcf;
    }
  }
  .item-bt-date{
    color: rgba(222, 222, 222, 0.56);
  }

  // list-loading
  .list_loading{
    .item{
      &:hover{
        background: linear-gradient(180deg, #383736 4.84%, #2f2f2d 98.82%);
        box-shadow: 0px 3px 4px rgba(32, 32, 32, 0.25);
      }
      .item-language-item{
        width: 200px;
        background: rgba(70, 70, 70, 0.616);
      }
      .item-language,
      .item-bt{
        background: none;
      }
      div{
        background: rgba(70, 70, 70, 0.616);
        border-radius: 4px;
      }
    }
  }
  .detailpop{
    background: rgba(0,0,0,0.5);
    .detail{
      background: rgba(60,60,60,0.8);
      /deep/ .con,
      /deep/ .nostars,
      /deep/ .markdown-body{
        background: none;
      }
    }
  }
}

</style>
